import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Tags from "../tags"
import { Link } from "gatsby-plugin-modal-routing"

const Wrapper = styled.section`
  width: 100%;
  max-width: 550px;
  margin: auto;
  background: ${props => props.theme.darker};
  padding-bottom: 20px;
  box-sizing: border-box;
  margin-bottom: 50px;
  border-radius: 8px;
  transition: all 0.5s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
`
const H1 = styled.h1`
  font-weight: 400;
  font-size: 1.5em;
  margin: 0 0 0 20px;
`
const LogoWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  overflow: hidden;
`
const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
`
const TagWrapper = styled.div`
  padding: 0 20px;
  box-sizing: border-box;
`
const LinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
`
const A = styled(Link)`
  padding: 10px;
  background: ${({ theme }) => theme.font};
  color: ${({ theme }) => theme.darker};
  text-decoration: none;
  border-radius: 7px;
  transition: all 0.5s ease-in-out;
`
const SLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.font};
`

const Project = ({ node }) => {
  return (
    <SLink
      to={`/projekte/${node.frontmatter.slug}`}
      state={{
        modal: true,
        noScroll: true,
      }}
      asModal
    >
      <Wrapper>
        <Header>
          <LogoWrapper>
            <Img
              fluid={node.frontmatter.logo.childImageSharp.fluid}
              alt={node.frontmatter.title + " Logo"}
            />
          </LogoWrapper>
          <H1>{node.frontmatter.title}</H1>
        </Header>
        <Img
          fluid={node.frontmatter.thumbnail.childImageSharp.fluid}
          alt={node.frontmatter.title}
        />
        <LinkWrapper>
          <A
            to={`/projekte/${node.frontmatter.slug}`}
            state={{
              modal: true,
              noScroll: true,
            }}
            asModal
          >
            Mehr erfahren
          </A>
        </LinkWrapper>
        <TagWrapper>
          <Tags tagString={node.frontmatter.tags} contrast noMaxWidth />
        </TagWrapper>
      </Wrapper>
    </SLink>
  )
}

export default Project
